import * as React from "react";
import { useEffect } from "react";

import { Helmet } from "react-helmet";
import { Page } from "../components/Page";
import Container from "../components/Container";
import DefaultLayout from "../layouts/default";
import {
  EventApi,
  EventAttributes,
  TicketPaymentDataAttributesResultEnum,
} from "../api";
import { defaultAPIConfig } from "../config/defaultApiConfig";
import ContentWrapper from "../components/Wrappers/ContentWrapper";
import { InhaltElem } from "../components/Text/SmallerInhalt";

interface ReservationCompletedTemplateProps {
  pageContext: {
    attributes: EventAttributes;
    id: string;
  };
}

const api = new EventApi(defaultAPIConfig());

const ReservationCompletedTemplate: React.FC<ReservationCompletedTemplateProps> = (
  props
) => (
    <DefaultLayout>
      <Helmet>
        <title>Reservation successful! - STEP</title>
      </Helmet>
      <Page>
        <Container>
          <ContentWrapper>
            <br />
            <br />
            <br />
            <InhaltElem>
              <h1>Reservation successful</h1>
              <br />
              <p>We will get in touch with you.</p>
            </InhaltElem>
          </ContentWrapper>
        </Container>
      </Page>
    </DefaultLayout>
  );

export default ReservationCompletedTemplate;
